<template>
  <div>
    <div class="flex md12">
      <va-card :title="'Your Tickets'">
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search Users'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-modal
          v-model="CurrentTicketPopup"
          hideDefaultActions
          class="flex xs12"
          title="Ticket Data"
          size="large"
          ok-text="Ok">
        <section>  

          <!-- ID's Area -->
          <p>
            <b>Attached Admin ID: </b> 
            <router-link title="Click to see more" :to="'/users/edituser/' + CurrentTicketPopup.adminID">{{CurrentTicketPopup.adminID}}</router-link>
          </p>
          <p>
            <b>Evaluation ID:</b>
            <router-link title="Click to see more" :to="'/editor/eval/' + CurrentTicketPopup.evaluation">{{CurrentTicketPopup.evaluation}}</router-link>
          </p>
          <p><b>Exam ID:</b> {{CurrentTicketPopup.examID}}</p>
          <br/>
         
          <!-- User Area -->
          <div>
            <b>User ID: </b> 
            <router-link title="Click to see more" :to="'/users/edituser/' + CurrentTicketPopup.userID" >{{CurrentTicketPopup.userID}}</router-link>
          </div>
          <div>
            <b>User Name: </b>{{ CurrentTicketPopup.user }}
          </div>
          <div>
            <b>User Email: </b>{{ CurrentTicketPopup.email }}
          </div>
          <div>
            <b>User State: </b>{{ CurrentTicketPopup.stateCode }}
          </div>
          <div>
            <b>User Credential: </b>{{ CurrentTicketPopup.credentialCode }}
          </div><br/>


          <!-- Ticket Area -->
          <div>
            <b>Ticket ID: </b>{{CurrentTicketPopup.id}} 
          </div>
          <div>
            <b>Status:</b> {{ CurrentTicketPopup.fullStatus }}
          </div>
          <div>
            <b>Creation Date:</b> {{ CurrentTicketPopup.creationDate }} (YYYY/MM/DD)
          </div>
          <div>
            <b>Last Update:</b> {{ CurrentTicketPopup.updatedAt }} (YYYY/MM/DD)
          </div>

        </section>
         <!-- create 5 button with different actions -->
         <div class="pt-2">

         
            <va-button
                slot="actions"
                @click="viewQuestionsTriggered(CurrentTicketPopup)"
                color="primary"
                class="mr-2"
            > Feedback </va-button>
            <va-button
                slot="actions"
                @click="viewAvailableNotes(CurrentTicketPopup)"
                color="primary"
                class="mr-2"
            > Notes </va-button>
            <va-button
                slot="actions"
                @click="SendMessage({id: CurrentTicketPopup.id, email: CurrentTicketPopup.email})"
                color="primary"
                class="mr-2"
            > Reply Ticket </va-button>
            <va-button
                slot="actions"
                @click="EditStatus(CurrentTicketPopup.id, CurrentTicketPopup.status)"
                color="primary"
                class="mr-2"
            > Change Status </va-button>
            <va-button
                slot="actions"
                @click="viewEvaluationData(CurrentTicketPopup.evaluation, CurrentTicketPopup.userID)"
                color="primary"
                class="mr-2"
            > Evaluation  </va-button>
            <va-button
                slot="actions"
                @click="attachAdmin(CurrentTicketPopup.id, CurrentTicketPopup.adminID)"
                color="primary"
                class="mr-2"
            > Attach Admin </va-button>
           

            <va-button
                slot="actions"
                @click="CurrentTicketPopup = false"
                color="gray"
                class="mr-2"
            > Close </va-button>
          </div>
        </va-modal>
  
        <va-modal
          v-model="showNotesModal"
          hideDefaultActions
          class="flex xs12"
          title="Available Notes"
          size="large"
          ok-text="Ok">
          <va-data-table
              :fields="NoteFields"
              :data="NoteFilteredData"
              :per-page="5"
          >
          <template slot="actions" slot-scope="props">
            <va-button
              @click="deleteCurrentNote(props.rowData)"
              color="danger"
              icon="fa fa-trash"
              class="mr-2"
            />
          </template>
          </va-data-table>

           

           <div class="pt-2">
            <va-button
                slot="actions"
                @click="CreateNewNote"
                color="primary"
                class="mr-2"
            > Create New</va-button>
            <va-button
                slot="actions"
                @click="showNotesModal = false; currentTicket = false;"
                color="primary"
                class="mr-2"
            > Close </va-button>
          </div>
        </va-modal>
        <TicketData 
          @cancel="closePopup('tickets')"
          :ticketsFilteredData="ticketsFilteredData"
          :data="showMediumModal"
        />
        <MessageView
          @cancel="closePopup('messages')"
          @newMessage="newMessage"
          :messages="messageHistory"
          :ticketData="currentTicket"
          :data="showMessageModal"
        />
        <va-data-table
          :fields="fields"
          :data="filteredData"
          @row-clicked="showTicketData"
          :per-page="parseInt(perPage)"
          :loading="loading"
          clickable
          hoverable
        >
        <template slot="actions" slot-scope="props" >
          <div class="flex row" @click="ticketPopup()">
              <va-button @click="viewEvaluationData(props.rowData.evaluation, props.rowData.userID)" title="View Evaluation Data"   icon="fa fa-file-text"/>
              <va-button @click="viewAvailableNotes(props.rowData)" title="View Available Notes"  icon="fa fa-paperclip"/>
              <va-button @click="viewQuestionsTriggered(props.rowData)" title="View Questions Triggered"  icon="fa fa-ticket"/>
              <va-button @click="attachAdmin(props.rowData.id, props.rowData.adminID)"  title="Attach Admin"  icon="fa fa-link"/>
              <va-button @click="SendMessage({id: props.rowData.id, email: props.rowData.email})" title="Send Message (Email)"  icon="fa fa-envelope"/>
              <va-button @click="ViewMessages({id: props.rowData.id, email: props.rowData.email, adminID: props.rowData.adminID, userID: props.rowData.userID, message: props.rowData.message, creationDate: props.rowData.timestamp})"  title="View Messages"  icon="fa fa-comments"/>
              <va-button @click="EditStatus(props.rowData.id, props.rowData.status)"  title="Edit Status"  icon="fa fa-edit"/>
          </div>
        </template>
       </va-data-table>
       
      </va-card>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import moment from 'moment';
import firebase from 'firebase';
import axios from '@/scripts/interceptor.js'
import TicketCatcher from '../../class/TicketCatcher';
import TicketData from './modals/TicketData.vue';
import MessageView from './modals/MessageView.vue';
export default {
  data() {
    return {
      categories: [],
      showTicketPopup: true,
      loading: true,
      regradeLoading: false,
      currentTicket: false,
      CurrentTicketPopup: false,
      selectedReply: false,
      showMediumModal: false,
      showMessageModal: false,
      showNotesModal: false,
      term: null,

      admins: [],
      emailErrors: [],
      tickets: [],
      perPageOptions: ['10', '50', '100', '250'],
      availableTests: [],
      ticketLabels: [],
      messageHistory: [],
      noteLabels: [],
      
      email: '',
      perPage: '10',
    };
  },
  components: {
    TicketData,
    MessageView
  },
  async created() {
      let uid = firebase.auth().currentUser.uid;

      let categoriesPromise = this.$db.collection('evalCategories').get();
      let ticketsPromise = this.$db.collection('evalTickets')
        .where('adminID', '==', uid)
        .where("evalID", "!=", false)
        .get();
      let credentialsPromise = this.$db.collection('credentials').get();
      let statesPromise = this.$db.collection('states').get();

      let [categories, tickets, credentials, states] = await Promise.all([
        categoriesPromise,
        ticketsPromise,
        credentialsPromise,
        statesPromise
      ]);

      categories.forEach((category) => {
        this.categories.push({
          id: category.id,
          ...category.data(),
        });
      });

      const credentialData = credentials.docs.map((doc) => doc.data());
      const stateData = states.docs.map((doc) => doc.data());

      await Promise.all(tickets.docs.map(async (exam) => {
          const data = exam.data();
          const user = await this.$db.collection('users').doc(data.userID).get();
          const userData = user.data();
          //foreach word make first letter uppercase
          const displayName = userData?.displayName ? userData.displayName : 'N/A';
          const name = displayName.split(' ').map((word) => {
              return word.charAt(0).toUpperCase() + word.slice(1);
          }).join(' ');
          //check if status exists, if not, set to 'pending'

          if(data.status){
            switch (data.status) {
              case 'pending':
                data.fullStatus = 'Pending';
                break;
              case 'inprogress':
                data.fullStatus = 'In Progress';
                  break;
              case 'resolved':
                data.fullStatus = 'Resolved';
                  break;
              default:
                data.fullStatus = 'Pending';
                break;
            }
          }else{
            data.status = 'pending';
            data.fullStatus = 'Pending';
          }
          //check if data has 'updatedAt' field, if not, set to 'creationDate
          let updatedAt = data.updatedAt ? data.updatedAt : data.creationDate;

          //get state "name" from stateData using userDoc.stateCode
          let stateName = stateData.find((state) => {
            return state.abbreviation == userData.stateCode;
          })?.name || 'N/A';
          //get credential "name" from credentialData using userDoc.credentialCode
          let credentialName = credentialData.find((credential) => {
            return credential.value == userData.credentialCode;
          })?.displayName || 'N/A';

          this.tickets.push({
            id: exam.id,
            userID: data.userID,
            stateCode: stateName + ' - ' + userData.stateCode,
            credentialCode: credentialName + ' - ' + userData.credentialCode ,
            adminID: data.adminID,
            examID: data.examID,
            answers: data.answers,
            user: name,
            email: userData.email,
            evaluation: data.evalID,
            notes: data.notes,
            fullStatus: data.fullStatus,
            status: data.status,
            tests: 'example',
            creationDate: moment(data.creationDate.toDate()).format('MM/DD/YYYY - HH:mm'),
            claimedDate: data?.claimedDate ? moment(data.claimedDate.toDate()).format('MM/DD/YYYY - HH:mm') : 'N/A',
            timestamp: data.creationDate,
            updatedAt: moment(updatedAt.toDate()).format('MM/DD/YYYY - HH:mm'),
          });
      }));

      this.loading = false;
      
      if (this.$route.params.ticket_id) {
        let ticket = this.$route.params.ticket_id;
        let item = this.tickets.find((item) => item.id === ticket);
        if (!item) {
          this.$swal.fire({
            title: 'Oops! Ticket not found!',
            text: 'The ticket you are trying to access does not exist or has been deleted, make sure you are using the correct link.',
            icon: 'warning',
            confirmButtonText: 'OK'
          });
          return;
        }
        this.ViewMessages(item);
      }
    },
  computed: {
    fields() {
      return [{
        name: 'user',
        title: 'Name',
        sortField: 'user',
        width: '13%',
      },
      {
        name: 'email',
        title: 'User Email',
        sortField: 'email',
        width: '13%',
      }, {
        name: 'evaluation',
        title: 'Evaluation ID',
        sortField: 'evaluation',
        width: '13%',
      },
      {
        name: 'fullStatus',
        title: 'Ticket Status',
        sortField: 'fullStatus',
        width: '12%',
      },
      {
        name: "claimedDate",
        title: "Claimed At",
        sortField: "claimedDate",
        width: "12%"
      },
      {
        name: 'creationDate',
        title: 'Created At',
        sortField: 'creationDate',
        width: '12%',
      },
      {
        name: '__slot:actions',
        title: 'Actions',
        width: '30%',
      },
      ];
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        // sort by timestamp.seconds desc
        this.tickets.sort((a, b) => {
          return b.timestamp.seconds - a.timestamp.seconds;
        });
        return this.tickets;
      }

      // filter by user
      const filteredByUser = this.tickets.filter((item) => {
        let returnUser = false;
        if (item.user) {
          if (item.user.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnUser = true;
          }
        }
        return returnUser;
      });

      // sort filtered results by timestamp.seconds desc
      const sortedFilteredData = filteredByUser.sort((a, b) => {
        return b.timestamp.seconds - a.timestamp.seconds;
      });

      return sortedFilteredData;
    },
    NoteFields(){
      return [{
        name: 'adminID',
        title: 'AdminID',
        sortField: 'adminID',
        width: '30%',
      },
      {
        name: 'note',
        title: 'Note',
        sortField: 'note',
        width: '50%',
      }, 
      {
        name: 'date',
        title: 'Created At',
        sortField: 'date',
        width: '20%',
      }, 
      {
        name: '__slot:actions',
        title: 'Actions',
        sortField: 'actions',
        width: '10%',
      }, 
      ];
    },
    NoteFilteredData() {
      if (!this.term) {
        //order by realDate
        this.noteLabels.sort((a, b) => {
          return b.realDate - a.realDate;
        });
        return this.noteLabels;
      }
      return this.tickets.filter((exam) => {
        return exam.user.toLowerCase().includes(this.term.toLowerCase());
      });
    },
    ticketsFields(){
      return [{
        name: 'header',
        title: 'Header',
        sortField: 'header',
        width: '50%',
      },
      {
        name: 'category',
        title: 'Category',
        sortField: 'category',
        width: '50%',
      }, 
      {
        name: 'answerLabel',
        title: 'Label',
        sortField: 'answerLabel',
        width: '50%',
      }, 
      {
        name: 'answerChoice',
        title: 'Selected',
        sortField: 'answerChoice',
        width: '50%',
      }, 
      ];
    },
    ticketsFilteredData() {
      if (!this.term) {
        return this.ticketLabels;
      }
      return this.tickets.filter((exam) => {
        return exam.user.toLowerCase().includes(this.term.toLowerCase());
      });
    },
  },
  methods: {
   async ViewMessages(data){
    this.showMessageModal = true;
    this.currentTicket = data;
    this.loadMessages(data?.id);
   },
   async newMessage(event){
      //add message to the current messageHistory
      this.messageHistory.push({
        message: event.message,
        senderUID: firebase.auth().currentUser.uid,
        createdAt: firebase.firestore.Timestamp.now(),
      });
   },
   async loadMessages(ticketID){
    this.messageHistory = [];
    if(ticketID){
      const { messages } = await new Promise((resolve, reject) => {
            axios.get('/api/notifications/messages/history/'+ticketID)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
      });
      this.messageHistory = [];
      //First Message Push (Always the user message)
      if(this.currentTicket.message){
        this.messageHistory.push({
          message: this.currentTicket.message,
          senderUID: this.currentTicket.userID,
          createdAt: this.currentTicket.creationDate,
        });
      }
      this.messageHistory = this.messageHistory.concat(messages);
    }
   },
   async SendMessage(data) {
     let htmlSelectOptions = '';
     //list items from evalTicketReplies collection 
     this.$swal.fire({
              title: 'Loading Models',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: async () => {
                  this.$swal.showLoading();
                  //fetch from firestore in collection users, the ones that has isAdmin = true
                  await firebase.firestore().collection('evalTicketReplies').get().then((result)=>{
                    let data = result.docs.map((doc) => {
                      return {
                        key: doc.id,
                        value: doc.data().title,
                        message: doc.data().message,
                        subject: doc.data().subject,
                      };
                    });
                    this.replies = data;
                  })
                  this.replies.unshift({key: 'default', value: 'Select a Model (Default)'});
                  const inputOptions = this.replies.reduce((o, i) => {
                    o[i.key] = i.value
                    return o
                  }, {})
                  for (const [key, value] of Object.entries(inputOptions)) {
                    htmlSelectOptions += `<option label="${value}" value="${key}">${value}</option>`;
                  }
                  TicketCatcher.reply(htmlSelectOptions, data, this.replies).then( () => {
                    this.CurrentNihssTicketPopup = false;
                  });
              },
    })
   },
   ticketPopup(data){
   this.showTicketPopup = false
   },
   closePopup(type){
    switch (type) {
      case 'tickets':
        this.showMediumModal = false;
        break;
      case 'messages':
        this.showMessageModal = false;
      default:
        break;
    }
   },
   showTicketData(data){
    //show swal with html content of ticket data
    if(this.showTicketPopup){
       //foreach answer in data.answers create an array containing category and answer
       let answers = [];
      data.answers.forEach(answer => {
        answers.push(answer.category);
      });
      //for the categories with the same name, unite them into one object
      let uniqueAnswers = answers.filter((v, i, a) => a.indexOf(v) === i);
      //find name in this.categories
      let categories = TicketCatcher.handleCategories(uniqueAnswers, this.categories)
      //create html content
      data.categories = categories.join(', ');
      this.CurrentTicketPopup = data;    
    }else{
      this.showTicketPopup = true;
    }
   },
   viewEvaluationData(evalID, userID){
    this.$swal.fire({
      title: 'Are you sure?',
      text: 'You will be redirected to the evaluation data page',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, view it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.$router.push({name: 'evaluation-statistics-unique-eval', params: {userID: userID, evalID: evalID}});
      }
    });
   },
   viewQuestionsTriggered(data){
    this.showMediumModal = true;
    this.ticketLabels = [];
    this.ticketLabels = TicketCatcher.getTicketLabels(data.answers, this.categories);
   }, 
   CreateNewNote(){
    //get id of logged in user ujsing firebase
    //get id of current ticket
    let user = firebase.auth().currentUser;
    let userID = user.uid;
   
    //open swal to user insert note, then use firebase to save in "notes" array from current Ticket
    this.$swal.fire({
      title: 'Create a new note',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      preConfirm: (note) => {
        return note
      },
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then((result) => {
      if (result.value) {
        this.$swal.fire({
          title: 'Note created!',
          text: 'Your note has been created',
          type: 'success',
          confirmButtonText: 'Ok',
        });
        //push to this.currentTicket.notes if exists, else create array
        this.currentTicket.notes = this.currentTicket.notes ? this.currentTicket.notes : [];
        //push new note to array
        this.currentTicket.notes.push({
          note: result.value,
          adminID: userID,
          creationDate: new Date(),
        });
        this.$swal.fire({
          title: 'Saving note...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          onOpen: async () => {
              this.$swal.showLoading();
              await firebase.firestore().collection('evalTickets').doc(this.currentTicket.id).update({
                notes: this.currentTicket.notes,
              });
              this.loadNotes(this.currentTicket);
              this.$swal.close();
          },
        })
      }
    });

   }, 
   deleteCurrentNote(DATA){
    //delete item with index data.id  from this.currentTicket.notes
    this.currentTicket.notes.splice(DATA.id, 1);
    this.$swal.fire({
      title: 'Deleting note...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: async () => {
          this.$swal.showLoading();
          await firebase.firestore().collection('evalTickets').doc(this.currentTicket.id).update({
            notes: this.currentTicket.notes,
          });
          this.loadNotes(this.currentTicket);
          this.$swal.close();
      },
    })
   },
   loadNotes(data){
    this.noteLabels = [];
    if(data.notes){
      data.notes.forEach((note, id) => {
        let formatedDate = note.creationDate
        //check if note.creationDate is a date or a string
        try {formatedDate =  moment(formatedDate.toDate()).format('DD/MM/YYYY'); } 
        catch (error) {
          formatedDate =  moment(formatedDate).format('DD/MM/YYYY');
        }
        this.noteLabels.push({
          id: id,
          adminID: note.adminID,
          note: note.note,
          realDate: note.creationDate,
          date: formatedDate,
        });
      });
    }
   },
   viewAvailableNotes(data){
    this.showNotesModal = true;
    //foreach content in data.answers inser into ticketLabels
    this.currentTicket = data;
    this.loadNotes(this.currentTicket);
   }, 
   async ShowAttachModal(id, currentAdmin){
     //fin d item in this.tickets that has id = id
      let admins = this.admins
      let item = this.tickets.find((item) => {
        return item.id === id;
      });
      let allowUnassign = item ? true : false;
      const inputOptions = admins.reduce((o, i) => {
        o[i.key] = i.value
        return o
      }, {})
      this.$swal.fire({
          title: 'Attach Admin',
          input: 'select',
          inputOptions: inputOptions,
          inputValue: currentAdmin,
          inputPlaceholder: 'Select an Admin',
          confirmButtonText: 'Save Changes',
          showCancelButton: true,
          //if allowUnassign add cancel button
          denyButtonText: allowUnassign ? 'Unassign Admin' : 'Deny',
          showDenyButton: allowUnassign ? true : false,
          inputValidator: (value) => {
              return new Promise((resolve) => {
                  if(!value){
                      resolve('You need to select an admin');
                  }else{
                      resolve();
                  }
              });
          }
      }).then((result) => {
          if (result.value) {
              //swal with loading, close after promise
              this.$swal.fire({
                  title: 'Saving Changes',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: () => {
                      let that = this;
                      this.$swal.showLoading();
                      firebase.firestore().collection('evalTickets').doc(id).update({
                        adminID: result.value,
                        claimedDate: firebase.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      }).then(() => {
                        this.$swal.fire({
                          title: 'Success!',
                          text: 'Admin Assigned has been updated',
                          icon: 'success',
                          confirmButtonText: 'OK'
                        }).then(() => {
                          //remove item from list
                          this.tickets = this.tickets.filter((item) => {
                            return item.id !== id;
                          });
                          this.CurrentTicketPopup = false;
                          //stop loading
                          that.$swal.hideLoading();
                        });
                      }).catch((error) => {
                        this.$swal.fire({
                          title: 'Error!',
                          text: error.message,
                          icon: 'error',
                          confirmButtonText: 'OK'
                        });
                      });
                  },
              });
          }
          //check if is deny button
          else if( result.dismiss === this.$swal.DismissReason.deny){
              //swal with loading, close after promise
              this.$swal.fire({
                  title: 'Saving Changes',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: () => {
                      let that = this;
                      this.$swal.showLoading();
                      firebase.firestore().collection('evalTickets').doc(id).update({
                        adminID: false,
                        claimedDate: firebase.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      }).then(() => {
                        this.$swal.fire({
                          title: 'Success!',
                          text: 'Assigned has been updated',
                          icon: 'success',
                          confirmButtonText: 'OK'
                        }).then(() => {
                          //remove item from list
                          this.tickets = this.tickets.filter((item) => {
                            return item.id !== id;
                          });
                          this.CurrentTicketPopup = false;
                          //stop loading
                          that.$swal.hideLoading();
                        });
                      }).catch((error) => {
                        this.$swal.fire({
                          title: 'Error!',
                          text: error.message,
                          icon: 'error',
                          confirmButtonText: 'OK'
                        });
                      });
                  },
              });
          }
      });
   },
   async attachAdmin(id, adminID) {
      //Only fetch admins if they haven't been fetched yet
      if(this.admins.length < 1){
        let that = this;
        this.$swal.fire({
                  title: 'Loading Admins',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: async () => {
                      this.$swal.showLoading();
                      await firebase.firestore().collection('users')
                      .where("isAdmin", "==", true)
                      .get().then((result)=>{
                               let data = result.docs.map((doc) => {

                                  let docData = doc.data();
                                  //transform displayName in format "Lastname, Firstname"
                                  let name = docData?.displayName ? docData.displayName.split(" ") : ["", ""];
                                  let lastName = name.pop();
                                  let firstName = name.join(" ");
                                  docData.displayName = lastName + ", " + firstName;
                                  //capitalize first letter of each word
                                  docData.displayName = docData?.displayName ? docData.displayName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
                                  return {
                                    key: doc.id,
                                    value: docData.displayName,
                                    lastName: lastName,
                                    firstName: firstName,
                                  };
                                });
                                 //alphabetical order by firstName key, if is null then by lastName
                                 data.sort((a, b) => {
                                  if(a.firstName == ""){
                                    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                                      return -1;
                                    }
                                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                                      return 1;
                                    }
                                    return 0;
                                  }else{
                                    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                                    return -1;
                                    }
                                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                                      return 1;
                                    }
                                    return 0;
                                  }
                                });
                                this.admins = data;
                      })
                      .catch((error) => {
                          that.$swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: 'Something went wrong while trying to fetch the admins! Please try again later.',
                          });
                      });
                      this.$swal.close();
                  },
        }).then((result) => {
          this.ShowAttachModal(id, adminID);
        })
      }else{
        this.ShowAttachModal(id, adminID);
      }
   },
   EditStatus(id, status) {
      //open swal with select input and let first option be the current status
      let inputOptions = {
        'pending': 'Pending',
        'inprogress': 'In Progress',
        'resolved': 'Resolved',
      }
      //find item in this.tickets that has id = id
      let item = this.tickets.find((item) => {
        return item.id === id;
      });
      this.$swal.fire({
          title: 'Edit Status',
          input: 'select',
          inputOptions: inputOptions,
          inputValue: status,
          inputPlaceholder: 'Select a status',
          confirmButtonText: 'Save Changes',
          showCancelButton: true,
          inputValidator: (value) => {
              return new Promise((resolve) => {
                  if (value === 'pending') {
                      resolve();
                  } else if (value === 'inprogress') {
                      resolve();
                  } else if (value === 'resolved') {
                      resolve();
                  } else {
                      resolve('You need to select something!');
                  }
              });
          }
      }).then((result) => {
          if (result.value) {
              //swal with loading, close after promise
              this.$swal.fire({
                  title: 'Saving Changes',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  onOpen: () => {
                      let that = this;
                      this.$swal.showLoading();
                      //save changes to firebase
                      firebase.firestore().collection('evalTickets').doc(id).update({
                        status: result.value,
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                      }).then(() => {
                        this.$swal.fire({
                          title: 'Success!',
                          text: 'Status has been updated',
                          icon: 'success',
                          confirmButtonText: 'OK'
                        }).then(() => {
                          item.status = result.value;
                          item.fullStatus = inputOptions[result.value];
                          //get current date and save into updatedAt
                          let date = new Date();
                          item. updatedAt = date.getFullYear()+ '/' + (date.getMonth() + 1) + '/' + date.getDate();
                          //stop loading
                          that.$swal.hideLoading();
                        });
                      }).catch((error) => {
                        this.$swal.fire({
                          title: 'Error!',
                          text: error.message,
                          icon: 'error',
                          confirmButtonText: 'OK'
                        });
                      });
                  },
              });
          }
      });
   },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
  }
};
</script>
